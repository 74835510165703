import React, { useState } from "react"

import { withReviews } from "./withReviews"
import { Title, Text, Button, Container, Emoji } from "./Reviews.styled"
import { ReviewCard } from "./ReviewCard"
import { StyledContainer } from "../../../styled/Container"
import { CardProfile } from "../../../components/CardProfile/CardProfile"
import { useData } from "../../../hooks/useData"
import { routeResolver } from "../../Wrappers/Helpers"
import { Link } from "gatsby"

export const Reviews = withReviews(({ mentors }: Props): JSX.Element => {
  const { data } = useData()

  return <>
    <Container topSpacing={`md`} spacing={`md`} width={`md`}>
      <Title>{mentors?.length > 0 ? "Leave a confidential review" : "You have no pending reviews."}</Title>
      {mentors?.length > 0 && <Text>The feedback you leave below will only be viewed by the She Mentors team and will not be posted publicly</Text>}
      {mentors?.length == 0 && (
        <>
          <Emoji>😀</Emoji>
          <Button as={Link} to={`/mentors`} size={`large`} theme={`primary`} colour={`dark`}>
            Browse Mentors
          </Button>
        </>
      )}
    </Container>
    <StyledContainer collapse={`<md`} spacing={`md`} width={`sm`}>
      {mentors?.map(item => {
        const { meta, slug, type } = item?.mentor
        return (
          <CardProfile
            size={"full"}
            image={item?.mentor?.image ? item?.mentor?.image : data.profile2Image.childImageSharp.gatsbyImageData}
            title={item?.mentor?.title}
            content={item?.mentor?.role}
            subtitle={item.mentor?.company}
            link={`/mentors${routeResolver({ item: { meta, slug }, type })}/feedback`}
            feedback={() => {}}
            horizontal
          />
        );
      })}
    </StyledContainer>
  </>;
})

export interface Props {
  mentors?: Array<any>
}
