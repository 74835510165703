import React, { useState } from "react"
import { Link } from "gatsby"

import { usePage } from "../../hooks/usePage"
import { useTemplate } from "../../hooks/useTemplate"
import { useData } from "../../hooks/useData"

import { Icon } from "../Icon/Icon"
import { Options } from "../Options/Options"
import {
  Card,
  CardLink,
  StyledLink,
  Frame,
  Recommended,
  Image,
  Title,
  Content,
  Subtitle,
  Badge,
  ViewLink,
  Button,
  ButtonContainer,
  WishlistButton,
  WishlistText,
  ToggleContent,
  ToggleRow,
  SkillsTitle,
  SkillsRow,
  Row,
  Column,
  Responsive,
} from "./CardProfile.styled"

export const CardProfile = ({
  link,
  horizontal,
  toggle,
  image,
  recommended,
  wishlist,
  title,
  content,
  size,
  badge,
  badgeIcon,
  subtitle,
  feedback,
}: Props): JSX.Element => {
  const [toggleActive, setActive] = useState(false)
  const Wrapper = link && !horizontal ? CardLink : CardLink
  const {
    mentors: { profileLink, wishlistAdd, topHit },
  } = usePage()
  const {
    mentor: { feedbackTitle, skills },
  } = useTemplate()

  const {
    data: { profilePlaceholder },
  } = useData()

  return (
    <Wrapper size={size} horizontal={horizontal} recommended={recommended} to={link && horizontal ? link : null}>
      <Card
        hover={toggle}
        onClick={toggle ? () => setActive(!toggleActive) : null}
        shadow={`medium`}
        size={size}
        horizontal={horizontal}
        recommended={recommended}
      >
        <Image image={{ ...(image || profilePlaceholder?.childImageSharp?.gatsbyImageData) }} size={size} horizontal={horizontal} />
        <Frame size={size} horizontal={horizontal} recommended={recommended}>
          {recommended || wishlist ? (
            <Row>
              <Column position={`left`} width={`md:w-2/3`}>
                {recommended ? (
                  <Recommended size={size} recommended={recommended}>
                    {topHit}
                  </Recommended>
                ) : null}
              </Column>
              {wishlist ? (
                <Column position={`right`} width={`md:w-1/3`}>
                  <Responsive screen={`md>`}>
                    <WishlistButton>
                      <Icon colour={`tertiary`} name={`heart`} size={`ml`} />
                    </WishlistButton>
                  </Responsive>
                </Column>
              ) : null}
            </Row>
          ) : null}
          <Title size={size} horizontal={horizontal}>
            {title}
          </Title>
          <Content size={size} horizontal={horizontal}>
            {content}
          </Content>
          {!horizontal ? (
            <Responsive screen={toggle ? `md>` : null}>
              <Button as={Link} theme={`primary`} colour={`outline`} size={size === `full` || size === `less-medium` ? `medium` : size} to={link}>
                {profileLink}
              </Button>
            </Responsive>
          ) : (
            <Subtitle size={size} horizontal={horizontal}>
              {subtitle}
            </Subtitle>
          )}
          {horizontal && badge ? (
            <Badge>
              <Icon name={badgeIcon} size={`m`} colour={`green`} />
              &nbsp;{badge}
            </Badge>
          ) : null}
        </Frame>

        {toggleActive ? (
          <ToggleContent screen={`<md`}>
            {skills.length ? (
              <SkillsRow>
                <SkillsTitle>{skills}</SkillsTitle>
                <Options size={`pill-small`} items={skills} unclickable={true} />
              </SkillsRow>
            ) : null}
            <ToggleRow>
              {wishlist ? (
                <Column position={`left`} width={`w-1/2`}>
                  <WishlistButton>
                    <Icon colour={`tertiary`} name={`heart`} size={`m`} />
                    <WishlistText>{wishlistAdd}</WishlistText>
                  </WishlistButton>
                </Column>
              ) : null}
              <Column position={`right`} width={`w-1/2`}>
                <StyledLink theme={`secondary`} to={link}>
                  {profileLink}
                </StyledLink>
              </Column>
            </ToggleRow>
          </ToggleContent>
        ) : null}

        {horizontal && feedback ? (
          <ButtonContainer>
            <Button theme={`primary`} colour={`dark`} size={`medium`} onClick={feedback}>
              {feedbackTitle}
            </Button>
          </ButtonContainer>
        ) : null}
      </Card>
    </Wrapper>
  )
}

export interface Props {
  badge?: string
  badgeIcon?: string
  content?: string
  feedback?: () => void
  horizontal?: boolean
  image?: any
  link: string
  recommended?: boolean
  size?: string
  subtitle?: string
  title: string
  toggle?: boolean
  wishlist?: boolean
  noMargin?: boolean
  skills?: Array<any>
}
