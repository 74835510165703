import tw, { styled } from "twin.macro"
import { StyledButton } from "../../../styled/Button"
import { H1, P } from "../../../styled/Text"
import { StyledContainer } from "../../../styled/Container"

export const Title = styled(H1)`
  ${tw`text-center text-lg lg:text-2xl mb-4 font-bold`}
  ${({ spacing }) => spacing && tw`mb-8 md:mb-12`}
`
export const Text = styled(P)`
  ${tw`text-center`}
`

export const Button = styled(StyledButton)`
  ${tw`block w-full md:w-1/2 mb-8 text-center`}
`

export const Container = styled(StyledContainer)`
  ${tw`flex flex-col items-center`}
`

export const Emoji = tw.p`text-12xl text-center w-full`