import React, { useMemo } from "react"
import { useApp } from "../../../hooks/useApp"

export const withReviews = Component =>
  React.memo(({ name = "Reviews" }) => {
    const { unReviewMentors } = useApp()

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          mentors={unReviewMentors}
        />
      ),
      [unReviewMentors]
    )
  })
