import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { H4, H6, P } from "../../styled/Text"
import { StyledButton } from "../../styled/Button"
import { StyledCard } from "../../styled/Card"
import { StyledLink } from "../../styled/Link"
import { StyledRow } from "../../styled/Row"
import { StyledColumn } from "../../styled/Column"
import { StyledResponsive } from "../../styled/Responsive"

export { StyledRow as Row } from "../../styled/Row"
export { StyledColumn as Column } from "../../styled/Column"
export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledLink } from "../../styled/Link"

export const Card = styled(StyledCard)`
  ${tw`text-center`}
  ${({ horizontal }) => (horizontal ? tw`text-left flex flex-wrap` : null)}
  ${({ horizontal, size }) => (horizontal && size === `full` ? tw`px-8 py-12 lg:px-10 lg:py-8 mb-6` : null)}
  ${({ recommended, size }) =>
    recommended && size === `full` ? tw`border-solid border-3 border-pink mb-0` : recommended ? tw`border-solid border-2 border-pink` : ``}
`
export const CardLink = styled(Link)`
  ${({ size }) => (size === "full" ? tw`block` : tw`inline-block`)}
`
export const Frame = styled.figcaption`
  ${({ horizontal }) => (horizontal ? tw`p-2 flex-1 flex flex-col justify-center` : null)}
`
export const Recommended = styled.div`
  ${tw`uppercase text-pink font-bold absolute inset-x-0 top-0 w-43 mx-auto -my-3 inline-block bg-white rounded-full border-solid border-2 border-pink px-2 py-1 text-xxxs text-center leading-none uppercase`}
  ${({ size }) =>
    size === `full` && tw`md:static md:text-xs md:mb-6 md:border-0 md:rounded-none md:block md:text-left md:p-0 md:mx-0 md:mt-0 md:w-auto`}
`
export const Image = styled(GatsbyImage)`
  ${tw`inline-block rounded-full`}
  ${({ size }) => (size === "small" ? tw`w-20 h-20 m-3` : null)}
  ${({ size }) => (size === "less-medium" ? tw`w-35 h-35 m-5` : null)}
  ${({ size }) => (size === "medium" ? tw`w-36 h-36 m-5` : null)}
  ${({ size, horizontal }) => (size === "large" && !horizontal ? tw`w-36 h-36 m-5` : null)}
  ${({ size, horizontal }) => (size === "large" && horizontal ? tw`block w-24 h-24 m-1` : null)}
  ${({ size, horizontal }) => (size === "full" && !horizontal ? tw`block w-34 h-34 max-w-full mx-auto mb-3 md:mb-6` : null)}
  ${({ size, horizontal }) => (size === "full" && horizontal ? tw`block w-24 h-24 md:w-46 md:h-46 ml-2 mr-4 md:mr-13` : null)}
`
export const Title = styled(H4)`
  ${tw`text-grey-mid md:text-grey-dark mb-1`}
  ${({ size, horizontal }) => (size === "small" || horizontal ? tw`lg:text-base text-base` : null)}
  ${({ size }) => (size === "less-medium" ? tw`lg:text-md text-md` : null)}
  ${({ size }) => (size === "medium" ? tw`lg:text-md text-md` : null)}
  ${({ size, horizontal }) => (size === "large" && !horizontal ? tw`lg:text-md text-md` : null)}
  ${({ size, horizontal }) => (size === "full" && horizontal ? tw`lg:text-lg text-lg mb-2` : null)}
  ${({ horizontal }) => (horizontal ? tw`text-grey-dark` : null)}
`
export const Content = styled(P)`
  ${tw`text-grey-mid md:text-grey-dark`}
  ${({ size, horizontal }) => (size === "small" || horizontal ? tw`mb-2 lg:text-xs text-xs` : null)}
  ${({ size }) => (size === "less-medium" ? tw`mb-4 lg:text-base text-base` : null)}
  ${({ size }) => (size === "medium" ? tw`mb-4 lg:text-base text-base` : null)}
  ${({ size, horizontal }) => (size === "large" && !horizontal ? tw`mb-4 lg:text-base text-base` : null)}
  ${({ size, horizontal }) => (size === "full" && horizontal ? tw`lg:text-base text-base` : null)}
`
export const Subtitle = styled(H6)`
  ${tw`mb-0 lg:text-xxs text-xxs text-grey-mid uppercase`}
  ${({ size, horizontal }) => (size === "full" && horizontal ? tw`lg:text-base text-base` : null)}
`
export const Badge = tw.span`flex mt-2 font-bold text-green text-xs leading-normal`
export const ViewLink = tw(StyledLink)`normal-case text-md text-right underline mt-4`
export const Button = styled(StyledButton)`
  ${tw`inline-block my-3 md:mb-0 md:mt-8`}
  ${({ size }) => (size === "less-medium" ? tw`my-4` : null)}
  ${({ center }) => center && tw`block w-auto`}
`
export const ButtonContainer = tw.div`w-full mt-4 flex items-center justify-center`
export const WishlistButton = tw.button`flex focus:outline-none`
export const WishlistText = tw(P)`ml-2 text-xxs`
export const ToggleContent = tw(StyledResponsive)`w-full pt-6 mt-6 mb-2 border-t border-grey-pale`
export const ToggleRow = tw(StyledRow)`px-2`
export const SkillsTitle = tw(H4)`mb-4`
export const SkillsRow = tw.div`mb-4`
